import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import ApplicationContainer from "../common/ApplicationContainer";
import ActionBar from "../common/ActionBar";
import BackButton from "../common/BackButton";
import NextButton from "../common/NextButton";
import {useNavigate} from "react-router-dom";
import {Body1, H4, H5} from "../common/Text";
import {Grid, Link, Paper, useTheme} from "@mui/material";
import TextFieldWrapper from "../editors/TextFieldWrapper";
import {Button, Icon} from "@nutrien/ddc-components";
import {useMst} from "../../models/Root";
import {css} from "@emotion/css";
import EntitySearchResultCard from "../EntityMembers/EntitySearchResultCard";
import SimpleDialog from "../common/SimpleDialog";
import {useFeatureFlags} from "../../hooks/useFeatureFlags";
import {ApplicantType} from "../../models/enums/ApplicantType";
import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import {ReactComponent as RedAlertOctagonIcon} from "../assets/RedAlertOctagonIcon.svg";
import NoAccessComponent from "../NoAccessComponent";

const YourEntity = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const {customer, branches, borrowingEntitySearch} = useMst();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showExistingAccountsDialog, setShowExistingAccountsDialog] = useState<boolean>(false);
  const [showUnhandledPartnershipDialog, setShowUnhandledPartnershipDialog] = useState<boolean>(false);

  const yourEntityDialogCss = {
    searchContainer: css`
      width: 375px;
      margin: auto;
    `,
    searchIcon: css`
      margin-right: 8px;
    `,
    noSearchResultsHeadingText: css`
      margin-bottom: 0px;      
    `,
    dialogActionContainer: css`
      border-top: solid 1px #E0E0E0;
      padding-top: 8px;
    `,
    noAbnOrAcnContainer: css`
      padding-top: 24px;
      text-align: center;
    `,
    governmentEntity: css`
      padding: 16px;
      background-color: ${theme.palette.error.light} !important;
    `
  }

  const isGovernmentOtherEntity = () => {
    return hasBorrowingEntity() && borrowingEntitySearch.borrowingEntitySearchResult.type === BorrowingEntityType.GovernmentOther;
  }

  const isEmptyBorrowingEntity = () => {
    return !borrowingEntitySearch.isSearching && borrowingEntitySearch.isNotFound;
  }

  const hasBorrowingEntity = () => {
    return !borrowingEntitySearch.isSearching && borrowingEntitySearch.borrowingEntitySearchResult ;
  }

  const checkHasExistingAccounts = () => {
    return !featureFlags.qaAllowDigitalPathWithExistingAccounts && borrowingEntitySearch.hasExistingAccounts;
  }

  const performSearch = async () => {
    await borrowingEntitySearch.search(true);

    if(checkHasExistingAccounts()) {
      setShowExistingAccountsDialog(true);
    }

    if(borrowingEntitySearch.isPartnershipWithTrustOrCompany()) {
      setShowUnhandledPartnershipDialog(true);
    }
  }

  const closeExistingAccountsDialog = () => {
    setShowExistingAccountsDialog(false);

    borrowingEntitySearch.reset();
  }

  const closeUnhandledPartnershipDialog = () => {
    setShowUnhandledPartnershipDialog(false);

    borrowingEntitySearch.reset();
  }

  const createDigitalApplication = async () => {
    await createApplicationForDigitalPath(borrowingEntitySearch.borrowingEntitySearchResult.id);
  }

  const createHobbyFarmDigitalApplication = async () => {
    if(!isSaving) {
      await createApplicationForDigitalPath();
    }
  }

  const createApplicationForDigitalPath = async (borrowingEntityId?: number | undefined | null) => {
    if(branches.selectedBranch) {
      try {
        setIsSaving(true);

        await customer.createApplicationForDigitalPath(branches.selectedBranch?.id, branches.activities.cropField.value,
          branches.activities.livestockField.value, branches.activities.woolField.value, borrowingEntityId);
        navigate("/entity");
      } finally {
        setIsSaving(false);
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);

    customer.load(sessionStorage.getItem("authorization") ?? "").then(() => {
      borrowingEntitySearch.initialize();

      if(customer.applicationId > 0) {
        // If Application is Already Set, then move to entity page
        navigate(customer.individual.applicantType === ApplicantType.LeadApplicant ? "/entity" : "/review");
      } else {
        // Redirect to Branches Page if there is no selected Branch
        if(!branches.selectedBranch) {
          navigate("/branches");
        }
      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SimpleDialog
        show={showExistingAccountsDialog}
        showCloseWindow={true}
        title={"Your organisation has existing accounts with us"}
        detail={"Based on your ABN, you have an existing account with us. If you’re looking to open a second account, please discuss with your branch."}
        onClose={() => closeExistingAccountsDialog()} />

      <SimpleDialog
        show={showUnhandledPartnershipDialog}
        showCloseWindow={true}
        title={"Go to your branch"}
        detail={"Unfortunately, we can't process an application online with a partnership that involves a Trust or a Company.  Please visit your branch to complete your application"}
        onClose={() => closeUnhandledPartnershipDialog()} />

      <ApplicationContainer label={"Trade Account Application"} isLoading={isLoading} isSaving={isSaving}>
        {customer.isLoaded && customer.individual.isCoApplicant() && (
          <NoAccessComponent/>
        )}
        {customer.individual.isLeadApplicant() && (
          <>
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"}>
          {isGovernmentOtherEntity() && (
            <Paper elevation={3} className={yourEntityDialogCss.governmentEntity}>
              <Grid container direction={"row"} spacing={2}>
                <Grid item>
                  <RedAlertOctagonIcon />
                </Grid>
                <Grid item color={theme.palette.error.dark}>
                  Applications for government and sporting clubs cannot be taken online.  Please visit your local branch
                </Grid>
              </Grid>
            </Paper>
          )}
          <Grid item>
            <H4 color={theme.palette.primary.main}>Enter your organisation's ABN or ACN</H4>
          </Grid>
        </Grid>
        <div className={yourEntityDialogCss.searchContainer}>
          <Grid container direction={"column"}>
            <Grid item>
              <TextFieldWrapper
                label={"ABN or ACN"}
                fieldModel={borrowingEntitySearch.searchTerm}
                startAdornment={<Icon.SearchFeather className={yourEntityDialogCss.searchIcon}/>}
                placeholder={"Type your ABN or ACN"}
              />
            </Grid>
            <Grid item>
              <Button
                id={"searchButton"}
                disabled={borrowingEntitySearch.searchTerm.value === "" || borrowingEntitySearch.isSearching || !borrowingEntitySearch.searchTerm.validationResult.isValid()}
                color="primary"
                variant="contained"
                isLoading={borrowingEntitySearch.isSearching}
                onClick={() => performSearch()}>
                Search
              </Button>
            </Grid>
            {isEmptyBorrowingEntity()  && (
              <Grid item>
                <H5 className={yourEntityDialogCss.noSearchResultsHeadingText}>
                  {`No Results for " ${borrowingEntitySearch.searchedTerm} "`}
                </H5>
                <Body1>
                  Try another search
                </Body1>
              </Grid>
            )}
            {hasBorrowingEntity() && (
              <Grid item>
                <H5>
                  {`Results for " ${borrowingEntitySearch.searchedTerm} "`}
                </H5>
              </Grid>
            )}
            {hasBorrowingEntity() && (
              <Grid item>
                <EntitySearchResultCard result={borrowingEntitySearch.borrowingEntitySearchResult} />
              </Grid>
            )}
            {!hasBorrowingEntity() && (
              <Grid item className={yourEntityDialogCss.noAbnOrAcnContainer}>
                <Link className="clickable" onClick={() => createHobbyFarmDigitalApplication()} color={theme.palette.info.dark}>Don't have an ABN or ACN? Click here</Link>
              </Grid>
            )}
          </Grid>
        </div>
          </>
        )}
      </ApplicationContainer>
      {customer.individual.isLeadApplicant() && (
        <ActionBar
          isLoading={isLoading}
          leftComponents={<BackButton onClick={() => navigate("/yourBranch")} />}
          rightComponents={<NextButton disabled={!hasBorrowingEntity() || checkHasExistingAccounts() || isSaving || isGovernmentOtherEntity()} onClick={() => createDigitalApplication()} >Next</NextButton>}
        />
      )}
    </>
  )
}

export default observer(YourEntity);