import { createContext, useContext } from "react";
import { Instance, types } from "mobx-state-tree";
import { defaultSystemMessagesModel, SystemMessagesModel } from "./SystemMessagesModel";
import { BrandModel, defaultBrandModel } from "./BrandModel";
import { CustomerModel, defaultCustomerModel } from "./CustomerModel";
import { defaultStatesModel, StatesModel } from "./StatesModel";
import {BranchesModel, defaultBranchesModel} from "./BranchesModel";
import {AnzsicCodes, defaultAnzsicCodesModel} from "./AnzsicCodesModel";
import {defaultVerificationCodeModel, VerificationCodeModel} from "./VerificationCodeModel";
import { defaultMembersModel, MembersModel } from "./MembersModel";
import {
    defaultHobbyFarmTermsAndConditionsModel,
    HobbyFarmTermsAndConditionsModel
} from "./HobbyFarmTermsAndConditionsModel";
import {AttachmentsModel} from "./AttachmentsModel";
import {BorrowingEntitySearch, defaultBorrowingEntitySearch} from "./BorrowingEntitySearch";

const defaultRootModelSnapshot = {
  brand: defaultBrandModel,
  systemMessages: defaultSystemMessagesModel,
  customer: defaultCustomerModel,
  states: defaultStatesModel,
  branches: defaultBranchesModel,
  anzsicCodes: defaultAnzsicCodesModel,
  verificationCode: defaultVerificationCodeModel,
  members: defaultMembersModel,
  hobbyFarmConditions: defaultHobbyFarmTermsAndConditionsModel,
  attachmentsModel: { attachments: [], parentId: 0 },
  borrowingEntitySearch: defaultBorrowingEntitySearch,
};

const RootModel = types
    .model({
      brand: BrandModel,
      systemMessages: SystemMessagesModel,
      customer:  CustomerModel,
      states: StatesModel,
      branches: BranchesModel,
      anzsicCodes: AnzsicCodes,
      verificationCode: VerificationCodeModel,
      members: MembersModel,
      hobbyFarmConditions: HobbyFarmTermsAndConditionsModel,
      attachmentsModel: AttachmentsModel,
      borrowingEntitySearch: BorrowingEntitySearch
    })
    .actions((self) => ({
      reset() {
        Object.assign(self, defaultRootModelSnapshot);
      }
    }));

export interface IRootModel extends Instance<typeof RootModel> {}
export const rootStore: IRootModel = RootModel.create(defaultRootModelSnapshot);

export const resetRootStore = () => {
  console.log("resetting rootStore...");
  rootStore.reset();
  console.log("...rootStore resetting");
};

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}