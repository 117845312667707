import _ from "lodash";
import { applySnapshot, cast, flow, Instance, types } from "mobx-state-tree";
import apiRoot from "../helpers/apiRoot";

export const CityPostcodeModel = types.model({
  city: types.string,
  state: types.string,
  postcode: types.string
});

export const CityPostcodesModel = types.model({
  searchCity: types.maybe(types.string),
  searchState: types.maybe(types.string),
  searchPostcode: types.maybe(types.string),

  cityPostcodes: types.array(CityPostcodeModel),

  cities: types.array(types.string),
  postcodes: types.array(types.string)
})
  .actions((self) => ({
    reset() {
      applySnapshot(self, defaultCityPostcodesModel);
    },
    initialize() {
      this.reset();
    },
    isValidCity(city: string | undefined, isRequired: boolean = true): boolean {
      return city ? self.cityPostcodes.map((x) => x.city.toUpperCase()).includes(city.toUpperCase()) : !isRequired;
    },
    isValidState(state: string | undefined, isRequired: boolean = true): boolean {
      return state ? self.cityPostcodes.map((x) => x.state.toUpperCase()).includes(state.toUpperCase()) : !isRequired;
    },
    isValidPostcode(postcode: string | undefined, isRequired: boolean = true): boolean {
      return postcode ? self.cityPostcodes.map((x) => x.postcode).includes(postcode) : !isRequired;
    },
    retrieve: flow(function* retrieve(city: string | undefined, state: string | undefined, postcode: string | undefined) {
      if (!city && !state && !postcode) return;
      if (city === self.searchCity && state === self.searchState && postcode === self.searchPostcode) return;

      // Remember previous search
      self.searchCity = city;
      self.searchState = state;
      self.searchPostcode = postcode;

      const cityPostcodes = yield apiRoot.lookupApi.getCityPostcodes(city, state, postcode);
      if (cityPostcodes.length > 0) {
        self.cityPostcodes.replace(cityPostcodes);

        self.cities.replace(_.orderBy(_.uniq(_.map(self.cityPostcodes, (x) => x.city)), x => x));
        self.postcodes.replace(_.orderBy(_.uniq(_.map(self.cityPostcodes, (x) => x.postcode)), x => x));
      }
    })
  }));

export const defaultCityPostcodesModel = {
  searchCity: undefined,
  searchState: undefined,
  searchPostcode: undefined,

  cityPostcodes: cast([]),
  cities: cast([]),
  postcodes: cast([])
} as Instance<typeof CityPostcodesModel>;