import { observer } from "mobx-react-lite";
import React from "react";
import { useMst } from "../../models/Root";
import { css } from "@emotion/css";
import { useTheme } from "@mui/material";

const BrandLogo = () => {
  const theme = useTheme();
  const { brand } = useMst();

  const brandLogoCss = {
    logoArea: css`
      height: 63px;
      margin: auto;
      background: ${theme.palette.background.paper};
      text-align: center;
    `,
    logo: css`
      margin-top: 12px;
      height: 38px;
    `
  };

  return (
    <>
      <div className={brandLogoCss.logoArea}>
        <img className={brandLogoCss.logo} src={brand.logoUrl} title={brand.name} aria-label={"Logo"} />
      </div>
    </>
  )
}

export default observer(BrandLogo);