import { Instance, types } from "mobx-state-tree";
import {nutrienTheme} from "../themes/NutrienTheme";
import {agriwestRuralTheme} from "../themes/AgriwestRuralTheme";
import {queenslandRuralTheme} from "../themes/QueenslandRuralTheme";
import {grantDanielLongTheme} from "../themes/GrantDanielLongTheme";
import {platinumTheme} from "../themes/PlatinumTheme";
import {brAndCAgentsTheme} from "../themes/BrAndCAgentsTheme";
import {dairyLivestockServicesTheme} from "../themes/DairyLivestockServicesTheme";
import {sejTheme} from "../themes/SejTheme";
import {davidsonCameronTheme} from "../themes/davidsonCameronTheme";
import {qfhMultipartsTheme} from "../themes/QFHMultipartsTheme";
import {dalbyRuralSuppliesTheme} from "../themes/DalbyRuralSupplies";
import {clarkeAndStokesTheme} from "../themes/ClarkeAndStokes";
import {greatNorthernRuralTheme} from "../themes/GreatNorthernRuralTheme";

const defaultBrand = { name: "Nutrien Ag Solutions", domain: "nutrienagsolutions.com.au", favIconUrl: "/logos/Nutrien.png", logoUrl: "/logos/nutrien.png", companyCode: "8200", theme: nutrienTheme, digitalPathEnabled: true };

const brands = [
  defaultBrand,
  { name: "Stevens Egan Johnston", domain: "apply.sej.com.au", favIconUrl: "/icons/SEJ.png", logoUrl: "/logos/SEJ.png", companyCode: "2010", theme: sejTheme, digitalPathEnabled: false },
  { name: "Clarke and Stokes", domain: "clarkeandstokes.com.au", favIconUrl: "/icons/ClarkeAndStokes.png", logoUrl: "/logos/ClarkeAndStokes.png", companyCode: "7008", theme: clarkeAndStokesTheme, digitalPathEnabled: true },
  { name: "Dalby", domain: "dalbyruralsupplies.com.au", favIconUrl: "/icons/DalbyRuralSupplies.png", logoUrl: "/logos/DalbyRuralSupplies.png", companyCode: "8430", theme: dalbyRuralSuppliesTheme, digitalPathEnabled: true },
  { name: "Great Northern Rural", domain: "greatnorthernrural.com.au", favIconUrl: "/icons/GreatNorthernRural.png", logoUrl: "/logos/GreatNorthernRural.png", companyCode: "7006", theme: greatNorthernRuralTheme, digitalPathEnabled: false },
  { name: "Platinum", domain: "platinumagservices.com.au", favIconUrl: "/icons/Platinum.png", logoUrl: "/logos/Platinum.png", companyCode: "6001", theme: platinumTheme, digitalPathEnabled: true },
  { name: "AgriWest", domain: "agriwestrural.com.au", favIconUrl: "/icons/AgriWest.png", logoUrl: "/logos/AgriWest.png", companyCode: "5005", theme: agriwestRuralTheme, digitalPathEnabled: true },
  { name: "QFH Multiparts", domain: "qfhmultiparts.com.au", favIconUrl: "/icons/QFHMultiparts.png", logoUrl: "/logos/QFHMultiparts.png", companyCode: "7009", theme: qfhMultipartsTheme, digitalPathEnabled: true },
  { name: "Queensland Rural", domain: "qldrural.com.au", favIconUrl: "/icons/Queensland.png", logoUrl: "/logos/queensland.png", companyCode: "3010", theme: queenslandRuralTheme, digitalPathEnabled: false},
  { name: "Grant Daniel Long", domain: "gdlrural.com.au", favIconUrl: "/icons/GrantDanielLong.png", logoUrl: "/logos/GrantDanielLong.png", companyCode: "3002", theme: grantDanielLongTheme, digitalPathEnabled: false},
  { name: "BR&C Agents Pty Ltd", domain: "brcagents.com.au", favIconUrl: "/icons/BR_C.png", logoUrl: "/logos/BR_C.png", companyCode: "2013", theme: brAndCAgentsTheme, digitalPathEnabled: false},
  { name: "Davidson Cameron Pty Ltd", domain: "dcco.com.au", favIconUrl: "/icons/davidsonCameron.png", logoUrl: "/logos/DavidsonCameron.png", companyCode: "5020", theme: davidsonCameronTheme, digitalPathEnabled: false},
  { name: "Dairy Livestock Services Pty Ltd", domain: "dairylivestockservices.com.au", favIconUrl: "/icons/DairyLivestockServices.png", logoUrl: "/logos/DairyLivestockServices.png", companyCode: "2006", theme: dairyLivestockServicesTheme, digitalPathEnabled: false}
];

export const BrandModel = types.model({
  isLoaded: types.boolean,
  name: types.string,
  favIconUrl: types.string,
  logoUrl: types.string,
  companyCode: types.string,
  digitalPathEnabled: types.boolean
})
  .views((self) => {
    return {
      getTheme() {
        const theme = brands.find(x => x.name === self.name)?.theme;
        return theme ?? nutrienTheme;
      }
    }
  })
  .actions((self) => {
    return {
      init() {
        self.isLoaded = false;

        const brand = brands.find(x => window.location?.host?.includes(x.domain));

        const brandToUse = brand ?? defaultBrand;
        self.name = brandToUse.name;
        self.favIconUrl = brandToUse.favIconUrl;
        self.logoUrl = brandToUse.logoUrl;
        self.companyCode = brandToUse.companyCode;
        self.digitalPathEnabled = brandToUse.digitalPathEnabled;

        self.isLoaded = true;
      }
    }
  });

export const defaultBrandModel = {
  isLoaded: false,
  name: defaultBrand.name,
  favIconUrl: "",
  logoUrl: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==", // CTA-2451 small transparent image so no image is visible until we've figured out what brand is being displayed
  companyCode: defaultBrand.companyCode,
  digitalPathEnabled: false
} as Instance<typeof BrandModel>;