import {observer} from "mobx-react-lite";
import {Grid, Paper, useTheme} from "@mui/material";
import React from "react";
import {IBorrowingEntityModel} from "../../models/BorrowingEntityModel";
import {Subtitle, TextBlock} from "../common/Text";
import {getBorrowingEntityTypeName} from "../../models/enums/BorrowingEntityType";
import {Icon} from "@nutrien/ddc-components";
import {css} from "@emotion/css";

interface Props {
  result: IBorrowingEntityModel;
}

const EntitySearchResultCard = (props: Props) => {
  const theme = useTheme();

  const entitySearchResultCardCss = {
    borrowingEntityCard: css`
      //width: 467px;
      padding: 16px;
    `,
    addressText: css`
      line-height: 24px !important;
    `
  }

  return (
    <Paper className={entitySearchResultCardCss.borrowingEntityCard}>
      <Subtitle>{props.result.legalName.value}</Subtitle>
      <TextBlock
        color={theme.palette.text.secondary}>{getBorrowingEntityTypeName(props.result.type)}</TextBlock>
      {props.result.abn.value && (
        <TextBlock>ABN {props.result.abn.value}</TextBlock>
      )}
      {props.result.acn.value && (
        <TextBlock>ACN {props.result.acn.value}</TextBlock>
      )}
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Icon.MapPinFeather fontSize="small" stroke={theme.palette.text.secondary}/>
        </Grid>
        <Grid item className={entitySearchResultCardCss.addressText}>
          <TextBlock>{props.result.businessAddress.getFormattedAddress()}</TextBlock>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default observer(EntitySearchResultCard)